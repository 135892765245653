import { FC } from 'react'

import BuyMoreCryptoIcon from 'images/why-loans-needed/buy-more-crypto-icon.svg'
import OptimizeTaxesIcon from 'images/why-loans-needed/optimize-taxes.svg'
import InvestInYourBusinessIcon from 'images/why-loans-needed/invest-in-your-business.svg'
import MakeHugePurchaseIcon from 'images/why-loans-needed/make-a-huge-purchase.svg'

import styles from './styles.module.scss'

export const WhyLoansNeeded: FC = () => {
  return (
    <div className={styles.whyLoanNeeded}>
      <div className={styles.title}>
        <p>One crypto loan — infinite possibilities</p>
      </div>
      <div className={styles.description}>
        <p>Get the most value from your crypto and afford yourself anything you dream of right now</p>
      </div>
      <div className={styles.cardList}>
        <div className={styles.cardItem}>
          <div className={styles.cardItemHead}>
            <div className={styles.cardItemIcon}>
              <img src={BuyMoreCryptoIcon} alt="Buy more crypto block" />
            </div>
            <div className={styles.cardItemTitle}>
              <p>Buy more crypto</p>
            </div>
          </div>
          <div className={styles.cardItemDescription}>
            <p>
              Tired of waiting endlessly for the all-time-high rate of your favorite coin? Keep holding your crypto and
              get liquidity to buy even more crypto without any hesitation.
            </p>
          </div>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.cardItemHead}>
            <div className={styles.cardItemIcon}>
              <img src={OptimizeTaxesIcon} alt="Optimize taxes block" />
            </div>
            <div className={styles.cardItemTitle}>
              <p>Optimize taxes</p>
            </div>
          </div>
          <div className={styles.cardItemDescription}>
            <p>
              Maximize your tax efficiency. Getting a crypto loan and its spending – are non-taxable events, because
              they do not generate direct earnings.
            </p>
          </div>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.cardItemHead}>
            <div className={styles.cardItemIcon}>
              <img src={MakeHugePurchaseIcon} alt="Optimize taxes block" />
            </div>
            <div className={styles.cardItemTitle}>
              <p>Make a huge purchase</p>
            </div>
          </div>
          <div className={styles.cardItemDescription}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>
              Don't put your dreams on hold. Leverage your crypto and put the cash towards a major expense. Buy a house
              or a car, travel to a new place or pay for studies.
            </p>
          </div>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.cardItemHead}>
            <div className={styles.cardItemIcon}>
              <img src={InvestInYourBusinessIcon} alt="Invest in your business block" />
            </div>
            <div className={styles.cardItemTitle}>
              <p>Invest in your business</p>
            </div>
          </div>
          <div className={styles.cardItemDescription}>
            <p>Reinvest your crypto holdings and make your company grow.</p>
            <p>Cover operational expenses, pay employees on time, or even buy another company.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
