import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export interface IQuery {
  [key: string]: string | string[]
}

export default function useLocationSearch() {
  const router = useRouter()
  const [query, setQuery] = useState({} as IQuery)

  useEffect(() => {
    const doubledKeys: string[] = []

    const newQuery = router.asPath
      .split('?')[1]
      ?.split('&')
      .reduce((acc, pair) => {
        const [key, value] = pair.split('=')

        if (acc[key]) {
          if (doubledKeys.includes(key)) {
            // check if already casted to array
            ;(<string[]>acc[key]).push(value) // using as array
          } else {
            doubledKeys.push(key) // marking that this key has array
            acc[key] = [<string>acc[key], value] // casting to array
          }
        } else {
          acc[key] = value // single value, just writing it
        }

        return acc
      }, {} as IQuery)

    setQuery(newQuery || {})
  }, [router.asPath])

  return query
}
