import SwiperBlock from 'components/ui/swiper'

import styles from './styles.module.scss'
import { slides } from './assets/slides'

export default function SwiperComponent() {
  return (
    <div className={styles.wrapper}>
      <SwiperBlock title={'Crypto community trusts us'} slides={slides} />
    </div>
  )
}
