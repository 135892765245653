import cn from 'classnames'

import IconPhone from './icons/phone.webp'
import styles from './styles.module.scss'
import IconCircle from './icons/circle-icon.svg'
import IconWorld from './icons/world-icon.svg'

export default function Notification() {
  return (
    <div className={styles.block}>
      <div className={cn('container', styles.blockWrapper)}>
        <div className={styles.imageBox}>
          <img src={IconPhone} alt="best bitcoin lending site" className={styles.image} width={559} height={695} />
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>We&apos;re always on the watch</h2>
          <div className={styles.contentBox}>
            <img
              src={IconWorld}
              alt="Borrow Bitcoin and other cryptocurrencies"
              width={52}
              height={52}
              loading="lazy"
            />
            <p className={styles.contentText}>
              We don&apos;t want you to lose your crypto, so we monitor your loan every moment of every day.
            </p>
            <p className={styles.contentText}>
              If your loan reaches any of <b>three limit zones</b>, we will immediately notify you about it by email and
              sms.
            </p>
          </div>
          <div className={styles.contentBox}>
            <img src={IconCircle} alt="cryptocurrency loans worldwide" width={67} height={67} loading="lazy" />
            <p className={styles.contentText}>
              Before or after you get your loan, our support team is always ready to help you and answer any of your
              questions.
            </p>
            <p className={styles.contentText}>
              <b>24/7 live support</b> is here up for you.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
