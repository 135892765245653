import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

import { useHeader } from 'utils/use-header'
import { parseCookies } from 'utils/cookie-parser'

import { saveReferralId } from 'libs/local-storage'
import useLocationSearch from 'libs/location-search'

import { useAppDispatch } from 'store/hooks'
import { fetchCurrencies } from 'store/reducers/currencies'

import { defaultQuestions } from 'components/ui/questions/questions'
import { Questions } from 'components/ui/questions'

import { WhyLoansNeeded } from 'pages/home/components/why-loans-needed'

import MainBlock from './components/main-block'
import SupportedCollateral from './components/supported-collateral'
import { HowToBorrow } from './components/how-to-borrow'
import SwiperComponent from './components/swiper-component'
import Notification from './components/notification'

export default function HomePage({ cookies }) {
  const query = useLocationSearch()
  const dispatch = useAppDispatch()
  const referralId = Array.isArray(query.referral) ? query.referral[0] : query.referral

  const { aff_sub, click_id, partner_id, clickid, wm_id } = query
  const { CLICK_ID, CLICK_ID_PDL, PARTNER_ID_PDL, CLICK_ID_RAFINAD, PARTNER_ID_RAFINAD } = cookies || {}

  const [cookie, setCookie] = useCookies([
    'CLICK_ID',
    'CLICK_ID_PDL',
    'PARTNER_ID_PDL',
    'CLICK_ID_RAFINAD',
    'PARTNER_ID_RAFINAD',
  ])

  useEffect(() => {
    dispatch(fetchCurrencies())
  }, [])

  const processSalesDoublerCookies = () => {
    if (!aff_sub || (CLICK_ID && CLICK_ID === aff_sub)) {
      return
    }

    setCookie('CLICK_ID', aff_sub, {
      path: '/',
      maxAge: 2592000, // Expires after 30 days
      sameSite: true,
    })
  }

  const processRafinadCookies = () => {
    if (
      !(clickid && wm_id) ||
      (CLICK_ID_RAFINAD && CLICK_ID_RAFINAD === clickid && PARTNER_ID_RAFINAD && PARTNER_ID_RAFINAD === wm_id)
    ) {
      return
    }

    setCookie('CLICK_ID_RAFINAD', clickid, {
      path: '/',
      maxAge: 2592000, // Expires after 30 days
      sameSite: true,
    })

    setCookie('PARTNER_ID_RAFINAD', wm_id, {
      path: '/',
      maxAge: 2592000, // Expires after 30 days
      sameSite: true,
    })
  }

  const processPDLProfitCookies = () => {
    if (
      !(click_id && partner_id) ||
      (CLICK_ID_PDL && CLICK_ID_PDL === click_id && PARTNER_ID_PDL && PARTNER_ID_PDL === partner_id)
    ) {
      return
    }

    setCookie('CLICK_ID_PDL', click_id, {
      path: '/',
      maxAge: 2592000, // Expires after 30 days
      sameSite: true,
    })

    setCookie('PARTNER_ID_PDL', partner_id, {
      path: '/',
      maxAge: 2592000, // Expires after 30 days
      sameSite: true,
    })
  }

  useEffect(() => {
    processSalesDoublerCookies()
    processPDLProfitCookies()
    processRafinadCookies()
  }, [query, cookie])

  useEffect(() => {
    if (referralId) {
      saveReferralId(referralId)
    }
  }, [referralId])

  const head = useHeader(
    'Instant Crypto Loan – Borrow Against Crypto | CoinRabbit',
    // eslint-disable-next-line max-len
    'Getting a loan against crypto is easy! Borrow against crypto fast and securely with CoinRabbit crypto lending platform. Get a crypto loan in more than 130 coins for an unlimited loan term. Receive up to 90% of your collateral on your crypto wallet in 15 minutes.',
    defaultQuestions,
    1,
    'Crypto Loans',
  )

  return (
    <>
      {head}
      <MainBlock />
      <WhyLoansNeeded />
      <HowToBorrow />
      <SupportedCollateral />
      <SwiperComponent />
      <Notification />
      <Questions />
    </>
  )
}

export async function getServerSideProps(context) {
  const { req, res } = context
  const cookies = parseCookies(req)

  if (res) {
    if (Object.keys(cookies).length === 0 && cookies.constructor === Object) {
      res.end()
    }
  }

  return {
    props: {
      cookies: cookies && cookies,
    },
  }
}
