import { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import cn from 'classnames'

import Calculator from 'components/ui/calculator'
import { useAppSelector } from 'store/hooks'
import { getCurrencies } from 'store/reducers/currencies'

import useViewport from 'libs/use-viewport'

import PartnerChangenow from './icons/partner-changenow.svg'
import PartnerGuarda from './icons/partner-guarda.svg'
import nowWalletLogo from './icons/nowwallet-logo.svg'
import nowWalletLogoMobile from './icons/nowwallet-logo-mobile.svg'
import TrustPilot from './icons/trustpilot-logo.svg'

import styles from './styles.module.scss'

export default function MainBlock() {
  const currencies = useAppSelector(getCurrencies)

  const isMounted = useRef(true)
  const [viewport] = useViewport()

  const [currenciesCount, setCurrenciesCount] = useState(0)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const updateCount = async () => {
      const newCurrenciesCount = currencies.filter((el) => el.is_loan_deposit_enabled || el.is_loan_receive_enabled)
        .length

      if (isMounted.current) {
        setCurrenciesCount(newCurrenciesCount)
      }
    }

    void updateCount()
  }, [currencies])

  return (
    <div className={styles.container}>
      <div className={styles.mainBlock}>
        <div className={cn('container', styles.mainBlockWrapper)}>
          <div className={styles.greeting}>
            <h1 className={styles.mainTitle}>Crypto Loans</h1>
            <h2 className={styles.titlePage}>
              <span className={styles.firstWord}>Get</span> Instant Funds <br /> Against Your Crypto
            </h2>
          </div>
          <div className={styles.additionalInfo}>
            <div className={styles.subtitleText}>
              <p>Access extra funds without selling your crypto</p>
              <p>Grow your portfolio or invest in your dreams</p>
            </div>
            <div className={styles.advantages}>
              <div className={styles.advantagesItem}>
                <p className={styles.advantagesMain}>Safe</p>
                <p className={styles.advantagesText}>
                  <span>Top-tier security,</span>
                  <span>Cold wallet storage</span>
                </p>
              </div>
              <div className={styles.advantagesItem}>
                <p className={styles.advantagesMain}>Easy</p>
                <p className={styles.advantagesText}>
                  <span>No credit check</span>
                </p>
              </div>
              <div className={styles.advantagesItem}>
                <p className={styles.advantagesMain}>Start</p>
                <p className={styles.advantagesText}>from $100</p>
              </div>
              <div className={styles.advantagesItem}>
                <p className={styles.advantagesMain}>{currenciesCount}+</p>
                <p className={styles.advantagesText}>Currencies</p>
              </div>
            </div>
            <div className={styles.partners}>
              <div className={styles.partnersBoxInner}>
                <span className={cn(styles.partnersBoxTitle, styles.desktopOnly)}>Our Partners</span>
                <div className={styles.partnersBoxItems}>
                  <a
                    href="https://changenow.io/"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    className={styles.partner}
                  >
                    <img src={PartnerChangenow} alt="changeNow" width={58} height={33} />
                  </a>
                  <a
                    href="https://guarda.com/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className={cn(styles.guarda, styles.partner)}
                  >
                    <img src={PartnerGuarda} alt="Guarda Wallet" width={65} height={50} />
                  </a>
                  <a
                    href="https://walletnow.app/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className={cn(styles.wallet, styles.partner, styles.mobileOnly)}
                  >
                    <Image
                      src={nowWalletLogoMobile}
                      alt="NOW wallet"
                      width={30}
                      height={32}
                      layout={'fixed'}
                      priority={true}
                    />
                  </a>
                </div>
              </div>
              <div className={cn(styles.partnersBoxInner, styles.desktopOnly)}>
                <span className={cn(styles.partnersBoxTitle, styles.desktopOnly)}>Recommended wallet</span>
                <div className={styles.partnersBoxItems}>
                  <a
                    href="https://walletnow.app/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className={cn(styles.wallet, styles.partner, styles.desktopOnly)}
                  >
                    <Image
                      src={nowWalletLogo}
                      alt="NOW wallet"
                      width={110}
                      height={50}
                      layout={'fixed'}
                      priority={true}
                    />
                  </a>
                </div>
              </div>
              <div className={styles.partnersBoxInner}>
                <span className={cn(styles.partnersBoxTitle, styles.desktopOnly)}>Rated Excellent</span>
                <div className={styles.partnersBoxItems}>
                  <a
                    href="https://www.trustpilot.com/review/coinrabbit.io"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className={cn(styles.trustpilot, styles.partner)}
                  >
                    <Image
                      src={TrustPilot}
                      alt="TrustPilot"
                      width={viewport.includes('mobile') ? 80 : 100}
                      height={viewport.includes('mobile') ? 50 : 70}
                      layout={'fixed'}
                      priority={true}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.calculator}>
            <Calculator />
          </div>
        </div>
      </div>
    </div>
  )
}
